














import firebase from 'firebase/app';
import { Component, Vue } from 'vue-property-decorator';
import { Config } from '../models/config';
import { Utils } from '../models/utils';
import { loadStripe } from '@stripe/stripe-js';

type PriceMapType = {
  priceId: string;
  points: number;
  price: number;
};

const priceMapSandbox: PriceMapType[] = [
  { priceId: 'price_1L9MPSLYLRbw96AZ8wcM7S4F', points: 600, price: 49.95 },
  { priceId: 'price_1L9MOjLYLRbw96AZvm1Kptzh', points: 200, price: 19.95 },
  { priceId: 'price_1L9MKLLYLRbw96AZXjJ0Zs0e', points: 90, price: 9.95 }
];

const priceMapProd: PriceMapType[] = [
  { priceId: 'price_1LD1HoLYLRbw96AZ9Dkyy9zy', points: 600, price: 49.95 },
  { priceId: 'price_1LD1HsLYLRbw96AZ3mx1inol', points: 200, price: 19.95 },
  { priceId: 'price_1LD1I1LYLRbw96AZVAT2hS0D', points: 90, price: 9.95 }
];

@Component({})
export default class Plan extends Vue {
  public priceMap = Utils.isTestEnv() ? priceMapSandbox : priceMapProd;
  public stripePK = Utils.isTestEnv()
    ? 'pk_test_51KwhEhLYLRbw96AZQUDDeAVm0pOBW4e7ZWVMm60i5UFJBFZn1mL7N9IDyYSPFnvKq283hsdFj1Ntow7SIyDEbp6d00ybouVdQo'
    : 'pk_live_51KwhEhLYLRbw96AZl1XRwWMh25POI1oKEVaOBCl14Rt8f0iY5PlDJFVFPpjwr7P772cK3TzQccsUSQbIs2qtpfDz008iW8taBa';

  public loading: boolean = false;

  public mounted() {
    //
  }

  public async purchase(priceObj: PriceMapType) {
    console.log(priceObj);
    this.loading = true;
    const session = await firebase.functions().httpsCallable('StripeAPI-createPointsCheckoutSession')({
      priceId: priceObj.priceId
    });
    this.loading = false;
    // console.log(session);
    const stripe = await loadStripe(this.stripePK);
    if (stripe === null) {
      return;
    }
    await stripe.redirectToCheckout({
      sessionId: session.data.session.id
    });
  }
}
